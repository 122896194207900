<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/produtos-servicos/new"
        editPath="/produtos-servicos/"
        subtitle="Produtos/Servicos"
        tooltip="Produtos/Servicos"
        nomeTelaDoManual="produtos-servicos-list"
        origemHistoricoAcao="/produtos-servicos"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="id" header="Código"> </Column>

            <Column field="nome" :sortable="true" sortField="nome" header="Produto/Serviço"> </Column>
            <Column field="classificacao" :sortable="true" sortField="classificacao" header="Classificação"> </Column>

            <Column field="createdAt" :sortable="true" sortField="createdAt" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userCreate.name }} </small>
                </template>
            </Column>

            <Column field="updatedAt" :sortable="true" sortField="updatedAt" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userUpdate.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            service: null
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
        this.load();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    created() {
        this.service = new BaseService('/produtos-servicos');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>

<style></style>